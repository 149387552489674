import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState } from "react";
import { Link, To } from "react-router-dom"
import styles from "./anchor.module.scss";



const Anchor = (props: {
    to: To;
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (
        <div
            className={`${styles.anchor}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <Link className={`mx-1`}
                to={props.to}>
                {props.children}
            </Link>
            <div 
                className={styles.hover__effect}
                style={{ opacity : `${isHovering ? "0.5" : "0" }`, height: `${isHovering ? "3px" : "0" }` }}>
            </div>
        </div>
    )
}

export default Anchor;