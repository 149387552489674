import About from "../../pages/about";
import Blogs from "../../pages/blogs";
import Home from "../../pages/home";
import Showcase from "../../pages/showcase";
import Tools from "../../pages/tools";


export type RoutesType ={
    name: string;
    label: string;
    component: React.FunctionComponent;
};

const routes: RoutesType[] = [
    {
        name: "/",
        label: "Home",
        component: Home
    },{
        name: "/blogs",
        label: "Blogs",
        component: Blogs
    },
    {
        name: "/tools",
        label: "Tools",
        component: Tools
    },
    {
        name: "/about",
        label: "About",
        component: About
    },
    {
        name: "/showcase",
        label: "Showcase",
        component: Showcase    
    }
]

export default routes;