import { Link } from "react-router-dom";
import Header from "../header";
import routes from "./routes";
import Anchor from "../../components/anchor/achhor";
import styles from "./nav.module.css";


const Nav = () => {

    return (<>
        <Header>
            <div className={`flex ${styles.header}`}>
                <div>
                    <Link className="flex" to="/"><img src="https://placehold.co/100x51.png" alt="Home" /></Link>
                </div>
                <div className="ml-auto">
                    <nav>
                        <ul className="flex pl-0 unstyled-list justify-space-evenly">
                            {routes.map((route) => {
                                return <li>
                                    <Anchor to={route.name} >{route.label}</Anchor>
                                </li>
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </Header>
    </>)
}

export default Nav;