import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Layout from './layout-components/layout';
import Home from './pages/home';
import Blogs from './pages/blogs';
import Contact from './pages/contact';
import NoPage from './pages/no-page';
import About from './pages/about';
import Showcase from './pages/showcase';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="tools" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="showcase" element={<Showcase />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
